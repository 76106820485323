import styled from 'styled-components'
import { Layout, Button, Input } from 'antd'

export const Wrapper = styled(Layout.Content)`
  padding: 24px;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const StyledForm = styled.form`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .field-wrapper {
    width: 100%;
    margin: 0;
  }
`

export const StyledButton = styled(Button)`
  margin-top: 20px;
  width: 400px;
`

export const DatePickersRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export const DataContainer = styled.div`
  width: 600px;
  padding: 20px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const Label = styled.span`
  font-weight: bold;
`

export const Value = styled.span`
  color: #555;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  height: 100%;
  margin-top: 20px;
`

export const ColumnContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const IntervalTestButton = styled(Button)`
  width: 400px;
`

export const StyledInput = styled(Input)`
  width: 300px;
`
