import axios from 'axios'
import paramsSerializer from 'helpers/paramsSerializer'
import { BO_API_URL, BO_API_URL3, JAVA_API_URL, MAIN_API_URL, SHIVA_KNOWLEDGE_BASE_URL } from 'const/env'

const authAxios = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (
        !Object.keys(config.headers).find(
          (headerKey) => headerKey.toLowerCase() === 'authorization'
        )
      ) {
        const token = localStorage.getItem('token')
        if (token) {
          config.headers.authorization = `Bearer ${token}`
        }
      }

      return config
    },
    (error) => Promise.reject(error)
  )
}

export const mainApiInstance = axios.create({
  baseURL: MAIN_API_URL,
  paramsSerializer,
})

export const simpleApiInstance = axios.create({
  baseURL: MAIN_API_URL,
})

const instance = axios.create({
  baseURL: BO_API_URL,
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
  paramsSerializer,
})

const javaInstance = axios.create({
  baseURL: JAVA_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer,
})

const instanceWithApplicationJson = axios.create({
  baseURL: BO_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer,
})

const instance3 = axios.create({
  baseURL: BO_API_URL3,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer,
})

const instance4 = axios.create({
  baseURL: SHIVA_KNOWLEDGE_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer,
})

authAxios(instance)
authAxios(javaInstance)
authAxios(mainApiInstance)
authAxios(instance3)
authAxios(instance4)
export const javaApiInstance = javaInstance
export const apiInstance = instance
export const apiInstance2 = instanceWithApplicationJson
export const apiInstance3 = instance3
export const apiInstance4 = instance4
