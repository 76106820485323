import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

// components
import { Layout, Spin } from 'antd'
import ModalsPortal from 'components/modals/ModalsPortal'
import Features from './Features'
import AdPackages from './AdPackages'
import Login from './Login'
import NotFound from './NotFound'
import Settings from './Settings'
import Retailers from './Retailers'
import EmailNotifications from './EmailNotifications'
import LinkConverter from './LinkConverter'
import Roles from './Roles'
import MobileAppVersions from './MobileAppVersions'
import MobileNotifications from './MobileNotifications'
import ExtensionVersions from './ExtensionVersions'
import DeletedRetailers from './DeletedRetailers'
import CustomerService from './CustomerService'
import Insights from './Insights'
import Payments from './Payments'
import Coupons from './Coupons'
import RuleSlugs from './RuleSlugs'
import Users from './Users'
import Header from 'components/Header'
import PageTitle from 'components/PageTitle'
import HeadFavicon from 'components/HeadFavicon'
import RoleRoute from 'components/RoleRoute'
import LandingPageImages from './LandingPageImages'
import SEOPages from './SEOPages'
import Giveaways from './Giveaways'
import Campaigns from './Campaigns'
import ShopDev from './ShopDev'
import Shops from './Shops'
import MarketingPages from './MarketingPages'
import PitaSettings from './PitaSettings'
import AdminLogs from './AdminLogs'
import Bonuses from './Bonuses'
import Transactions from './Transactions'
import PaymentTransactions from './PaymentTransactions'
import KProject from './KProject'
import CreditCardsComparison from './CreditCardsComparison'
import UserJourneyProject from './UserJourneyProject'
import { selectors as adminSelectors } from 'domains/admin'

// const
import ROUTES from 'const/routes'

// selectors
import { loadingSelector } from 'selectors/layout'
import { useHistory } from 'react-router'

const App = () => {
  const isLoading = useSelector(loadingSelector)
  const history = useHistory()
  const adminToken = useSelector(adminSelectors.token)

  useEffect(() => {
    if (!adminToken) history.push(ROUTES.LOGIN)
  }, [history, adminToken])

  return (
    <Layout>
      {adminToken && (
        <>
          <ModalsPortal />
          <PageTitle />
          <HeadFavicon />
          <Header />
        </>
      )}
      <Spin spinning={isLoading} size="large">
        <Switch>
          <Route path={ROUTES.LOGIN} component={Login} />
          <RoleRoute exact path={ROUTES.HOME} component={Shops} />
          <RoleRoute path={ROUTES.SETTINGS} component={Settings} />
          <RoleRoute path={ROUTES.RETAILERS} component={Retailers} />
          <RoleRoute
            path={ROUTES.DELETED_STORES}
            component={DeletedRetailers}
          />
          <RoleRoute path={ROUTES.FEATURES} component={Features} />
          <RoleRoute exact path={ROUTES.EDITORIAL} component={Retailers} />
          <RoleRoute path={ROUTES.AD_PACKAGES} component={AdPackages} />
          <RoleRoute
            path={ROUTES.CUSTOMER_SERVICE}
            component={CustomerService}
          />
          <RoleRoute path={ROUTES.INSIGHTS} component={Insights} />
          <RoleRoute path={ROUTES.LINK_CONVERTER} component={LinkConverter} />
          <RoleRoute path={ROUTES.USERS} component={Users} />
          <RoleRoute path={ROUTES.RULE_SLUGS} component={RuleSlugs} />
          <RoleRoute path={ROUTES.PAYMENTS} component={Payments} />
          <RoleRoute path={ROUTES.COUPONS} component={Coupons} />
          <RoleRoute
            path={ROUTES.EMAIL_NOTIFICATIONS}
            component={EmailNotifications}
          />
          <RoleRoute path={ROUTES.ROLES} component={Roles} />
          <RoleRoute
            path={ROUTES.MOBILE_APP_VERSION}
            component={MobileAppVersions}
          />
          <RoleRoute
            path={ROUTES.EXTENSION_VERSIONS}
            component={ExtensionVersions}
          />
          <RoleRoute
            path={ROUTES.CREDIT_CARDS_COMPARISON}
            component={CreditCardsComparison}
          />
          <RoleRoute path={ROUTES.K_PROJECT} component={KProject} />
          <RoleRoute
            path={ROUTES.USER_JOURNEY_PROJECT}
            component={UserJourneyProject}
          />
          <RoleRoute
            path={ROUTES.MOBILE_NOTIFICATIONS}
            component={MobileNotifications}
          />
          <RoleRoute path={ROUTES.PITA} component={PitaSettings} />
          <RoleRoute path={ROUTES.ADMIN_LOGS} component={AdminLogs} />
          <RoleRoute
            exact
            path={ROUTES.LANDING_PAGE_IMAGES}
            component={LandingPageImages}
          />
          <RoleRoute
            exact
            path={ROUTES.MARKETING_PAGES}
            component={MarketingPages}
          />
          <RoleRoute exact path={ROUTES.SEO_PAGES} component={SEOPages} />
          <RoleRoute exact path={ROUTES.GIVEAWAYS} component={Giveaways} />
          <RoleRoute exact path={ROUTES.SHOP_DEV} component={ShopDev} />
          <RoleRoute exact path={ROUTES.CAMPAIGN_DEALS} component={Campaigns} />
          <RoleRoute exact path={ROUTES.BONUSES} component={Bonuses} />
          <RoleRoute
            exact
            path={ROUTES.TRANSACTIONS_BY_DATE}
            component={Transactions}
          />
          <RoleRoute
            path={ROUTES.PAYMENT_TRANSACTIONS}
            component={PaymentTransactions}
          />

          <Route path="*" component={NotFound} />
        </Switch>
      </Spin>
    </Layout>
  )
}

export default App
