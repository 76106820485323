import React, { useEffect, useState } from 'react'
import { Divider, Spin, Typography, message } from 'antd'
import { Field, Form } from 'react-final-form'
import DatePicker from 'components/DatePicker/DatePicker'
import { DATE_FORMAT_DASH_REVERSED } from 'const/dates'
import { FIELD_NAMES } from 'const/forms'
import {
  ColumnContainer,
  StyledButton,
  StyledForm,
  Wrapper,
  RowContainer,
  StyledInput,
} from './UserJourneyProject.styles'
import Input from '../../components/Input/Input'
import { apiInstance4 } from 'utils/request'
import moment from 'moment'
import styled from 'styled-components'

const StyledHtmlContent = styled.div`
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  li {
    margin: 8px 0;
  }
`

const buildUserJourneyData = async ({ user_id, from_date, to_date }) => {
  return await apiInstance4.post('user_journey_data_builder', {
    user_id,
    from_date,
    to_date
  })
}

const analyzeUserJourney = async ({ user_id, from_date, to_date, user_prompt }) => {
  return await apiInstance4.post('analyze_user_journey', {
    user_id,
    from_date,
    to_date,
    user_prompt
  })
}

const UserJourneyProject = () => {
  const [analyzedData, setAnalyzedData] = useState(null)
  const [isAnalyzing, setIsAnalyzing] = useState(false)

  const handleSubmit = async (values) => {
    try {
      setIsAnalyzing(true)
      const { user_id, start_date, end_date, additional_instruction } = values
      const buildResponse = await buildUserJourneyData({
        user_id: user_id,
        from_date: moment(start_date).format(DATE_FORMAT_DASH_REVERSED),
        to_date: moment(end_date).format(DATE_FORMAT_DASH_REVERSED)
      })

      if (!buildResponse?.data?.success) {
        throw new Error('Failed to build user journey data')
      }

      let analysisComplete = false
      let attempts = 0
      const maxAttempts = 15 // 5 minutes timeout (15 * 20 seconds)

      while (!analysisComplete && attempts < maxAttempts) {
        if (attempts > 0) {
          await new Promise(resolve => setTimeout(resolve, 20000))
        }

        const analysisResponse = await analyzeUserJourney({
          user_id: user_id,
          from_date: moment(start_date).format(DATE_FORMAT_DASH_REVERSED),
          to_date: moment(end_date).format(DATE_FORMAT_DASH_REVERSED),
          user_prompt: additional_instruction || ""
        })

        if (analysisResponse?.data?.success) {
          analysisComplete = true
          setAnalyzedData(analysisResponse.data.data)
          return
        }

        attempts++
      }

      if (!analysisComplete) {
        throw new Error('Analysis timed out after 5 minutes')
      }
    } catch (error) {
      console.error(error)
      message.error(error.message || 'Failed to analyze user journey')
    } finally {
      setIsAnalyzing(false)
    }
  }

  useEffect(() => {
    return () => {
      setAnalyzedData(null)
    }
  }, [])

  return (
    <Wrapper>
      <Typography.Title level={3}>User Journey Project</Typography.Title>
      <Spin spinning={isAnalyzing} size="large">
        <RowContainer>
          <ColumnContainer>
            <Form
              destroyOnUnregister
              onSubmit={handleSubmit}
              render={({ handleSubmit, pristine }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <Typography.Title level={4}>Analyze user journey</Typography.Title>
                  <Field
                    required
                    name={FIELD_NAMES.userId}
                    label="User id"
                    placeholder="ID"
                    component={Input}
                  />

                  <Field
                    required
                    name={FIELD_NAMES.startDate}
                    render={({ input }) => (
                      <DatePicker
                        required
                        input={input}
                        format="YYYY-MM-DD"
                        placeholder="Start date"
                        label="Start date"
                        name={FIELD_NAMES.startDate}
                        style={{
                          width: '300px',
                        }}
                      />
                    )}
                  />

                  <Field
                    required
                    name={FIELD_NAMES.endDate}
                    render={({ input }) => (
                      <DatePicker
                        required
                        input={input}
                        format="YYYY-MM-DD"
                        placeholder="End date"
                        label="End date"
                        name={FIELD_NAMES.endDate}
                        style={{
                          width: '300px',
                        }}
                      />
                    )}
                  />

                  <Field
                    name='additional_instruction'
                    label="Additional instruction"
                    placeholder="Additional instruction for LLM"
                    component={Input}
                  />

                  <StyledButton
                    type="primary"
                    disabled={pristine}
                    onClick={handleSubmit}
                  >
                    Send
                  </StyledButton>
                </StyledForm>
              )}
            />
          </ColumnContainer>

          <ColumnContainer>
            {analyzedData ? (
              <>
                <Typography.Title level={4}>
                  LLM - User Journey Analysis - response
                </Typography.Title>
                <StyledHtmlContent
                  dangerouslySetInnerHTML={{ __html: analyzedData }}
                />
              </>
            ) : null}
          </ColumnContainer>
        </RowContainer>
        <Divider
          style={{
            margin: '20px 0',
          }}
        />
        <RowContainer>
        </RowContainer>
      </Spin>
    </Wrapper>
  )
}

export default UserJourneyProject
