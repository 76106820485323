import React from 'react'
import first from 'lodash/first'
import { JAVA_FIELD_NAMES } from 'const/forms'
import { Button, Layout } from 'antd'
import DatePicker from 'components/DatePicker/DatePicker'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { StyledForm } from './PaymentPackage.style'
import Select from 'components/Select/Select'
import {
  updatePaymentPackage,
  createPaymentPackage,
} from 'actions/views/paymentTransactions'
import useAction from 'utils/useAction'
import { useSelector } from 'react-redux'
import {
  getCustomers,
  getNetworks,
} from 'domains/paymentTransactions/selectors'
import SearchSelect from 'components/SearchSelect/WrappedField'
import { RELATIONSHIPS_NAMES } from 'const/api'
import {
  COMMISSION_TYPES_VALUES,
  SUPPORTED_COMMISSION_TYPES,
} from 'const/transactions'
import {
  required,
  requiredNetwork,
  zeroOrNaturalStrict,
} from 'helpers/validators'

const enumsMapper = (enums) => {
  return enums?.map((enumItem, index) => ({
    label: enumItem,
    value: index,
  }))
}

const PaymentPackage = ({ currentTransaction, closeModal }) => {
  const updatePaymentPackageFunc = useAction(updatePaymentPackage)
  const createPaymentPackageFunc = useAction(createPaymentPackage)
  const networks = useSelector(getNetworks)
  const customers = useSelector(getCustomers)

  const editMode = !!currentTransaction?.id

  const mappedNetworks = enumsMapper(networks)
  const mappedCustomers = enumsMapper(customers)

  const onSubmit = (data) => {
    editMode
      ? updatePaymentPackageFunc({ values: data, callback: closeModal })
      : createPaymentPackageFunc({ values: data, callback: closeModal })
  }

  const getFieldsByCommissionType = (commissionType) => {
    if (commissionType === COMMISSION_TYPES_VALUES.adPackage) {
      return (
        <>
          <Field
            name={JAVA_FIELD_NAMES.network}
            label="Network"
            component={Select}
            options={mappedNetworks}
            required
          />
          <Field
            name={JAVA_FIELD_NAMES.uidToken}
            label="UID Token"
            component={Input}
            required
          />
          <Field
            name={JAVA_FIELD_NAMES.rule}
            validate={required}
            label="Rule"
            component={SearchSelect}
            entity={RELATIONSHIPS_NAMES.rules}
            required
          />
        </>
      )
    }

    if (commissionType === COMMISSION_TYPES_VALUES.dataPackage || commissionType === COMMISSION_TYPES_VALUES.mobileDataPackage) {
      return (
        <Field
          name={JAVA_FIELD_NAMES.customer}
          label="Customer"
          component={Select}
          options={mappedCustomers}
          required
        />
      )
    }

    if (commissionType === COMMISSION_TYPES_VALUES.manual) {
      return (
        <>
          <Field
            name={JAVA_FIELD_NAMES.commissionOrderValue}
            label="Sale Value"
            component={Input}
            type="number"
            addonBefore="USD"
            required
            validate={zeroOrNaturalStrict}
          />
          <Field
            name={JAVA_FIELD_NAMES.network}
            label="Network"
            component={Select}
            options={mappedNetworks}
            defaultValue={mappedNetworks?.[5]?.value}
            required
            validate={requiredNetwork}
          />
          <Field
            name={JAVA_FIELD_NAMES.rule}
            validate={required}
            label="Rule"
            component={SearchSelect}
            entity={RELATIONSHIPS_NAMES.rules}
            required
          />
          <Field
            name={JAVA_FIELD_NAMES.commissionId}
            label="Commission Id"
            component={Input}
            disabled={editMode}
            required
            validate={required}
          />
        </>
      )
    }

    return null
  }

  return (
    <Layout.Content>
      <Form
        destroyOnUnregister
        onSubmit={onSubmit}
        initialValues={{
          ...currentTransaction,
          rule: currentTransaction?.[JAVA_FIELD_NAMES.ruleId],
        }}
        render={({ handleSubmit, pristine, values }) => {
          return (
            <StyledForm>
              <Field
                name={JAVA_FIELD_NAMES.commissionType}
                label="Commission type"
                component={Select}
                options={SUPPORTED_COMMISSION_TYPES}
                defaultValue={first(SUPPORTED_COMMISSION_TYPES).value}
                disabled={editMode}
                required
              />
              <Field
                name={JAVA_FIELD_NAMES.commissionValue}
                label="Commission Value"
                component={Input}
                type="number"
                addonBefore="USD"
                required
                validate={zeroOrNaturalStrict}
              />
              <Field
                name={JAVA_FIELD_NAMES.paymentDate}
                label="Payment Date"
                validate={required}
                component={DatePicker}
                required
              />
              {getFieldsByCommissionType(
                values[JAVA_FIELD_NAMES.commissionType]
              )}
              <Button type="primary" onClick={handleSubmit} disabled={pristine}>
                Confirm
              </Button>
            </StyledForm>
          )
        }}
      ></Form>
    </Layout.Content>
  )
}

export default PaymentPackage
