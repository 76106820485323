import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import {
  formatPaymentPackageToCreate,
  formatPaymentPackageToUpdate,
} from './formatters'
import { message } from 'antd'
import { JAVA_FIELD_NAMES } from 'const/forms'
import {
  CREATE_PAYMENT_PACKAGES_API,
  UPDATE_PAYMENT_PACKAGES_API,
  COMMISSION_TYPES_VALUES,
  COMMISSION_TYPES_KEYS,
} from 'const/transactions'

function* fetchPaymentTransactionsWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const { data } = yield call(api.getPaymentTransactions, payload)
    yield put(
      actions.fetchPaymentTransactionsSuccess({
        ...payload,
        paymentTransactions: data.list,
        pageCount: data.pages,
        page: data.request.page,
        size: data.request.size,
        networks: data.enums[0]?.values || [],
        customers: data.enums[2]?.values || [],
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchPaymentTransactionsFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* fetchTransactionDetailsWorker({ payload }) {
  try {
    const { data } = yield call(api.getTransactionDetails, payload)

    yield put(
      actions.fetchTransactionDetailsSuccess({
        ...payload,
        transactionDetails: data.entity,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchTransactionDetailsFailure())
  }
}

function* createPaymentPackageWorker({ payload }) {
  try {
    const { [JAVA_FIELD_NAMES.commissionType]: type, ...body } = payload.data
    body[JAVA_FIELD_NAMES.packageType] = type
    const { data } = yield call(
      CREATE_PAYMENT_PACKAGES_API[type],
      formatPaymentPackageToCreate(body)
    )
    if (data) {
      yield put(actions.createPaymentPackageSuccess(payload))
      message.success('Ad Package created')
      payload.callback()
    } else {
      yield put(actions.createPaymentPackageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createPaymentPackageFailure())
  }
}

function* updatePaymentPackageWorker({ payload }) {
  try {
    const { [JAVA_FIELD_NAMES.commissionType]: type, ...body } = payload.data
    const { data } = yield call(
      UPDATE_PAYMENT_PACKAGES_API[type],
      formatPaymentPackageToUpdate(body)
    )

    if (data) {
      yield put(actions.updatePaymentPackageSuccess())
    } else {
      yield put(actions.updatePaymentPackageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updatePaymentPackageFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(
      actions.fetchPaymentTransactions,
      fetchPaymentTransactionsWorker
    ),
    takeLatest(actions.fetchTransactionDetails, fetchTransactionDetailsWorker),
    takeLatest(actions.createPaymentPackage, createPaymentPackageWorker),
    takeLatest(actions.updatePaymentPackage, updatePaymentPackageWorker),
  ])
}
